import React, { useState } from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import Link from "../utils/link"

import Logo from "../assets/images/logo.svg"
import "./header.scss"

function Header({ closeAndNavigateTo }) {
  const data = useStaticQuery(query)

  const [isVisible, setIsVisible] = useState(false)

  const toggleMenu = () => {
    setIsVisible(!isVisible)
  }

  const scrollToSection = e => {
    e.preventDefault()
    if (window && document) {
      const section = document.querySelector(".services-section")
      if (section) {
        toggleMenu()
        window.scrollTo({
          top: section.offsetTop,
          left: 0,
          behavior: "smooth",
        })
      } else {
        navigate("/#services")
      }
    }
  }

  const navigateBack = e => {
    e.preventDefault()
    const position = parseInt(sessionStorage.getItem("positionInitial"))
    sessionStorage.removeItem("positionInitial")
    if (position) {
      sessionStorage.setItem("position", position)
    }
    navigate(closeAndNavigateTo)
  }

  return (
    <>
      <header className="header">
        <Link
          to="/"
          title={data.site.siteMetadata.title}
          className="header__logo"
        >
          <img src={Logo} />
        </Link>
        {closeAndNavigateTo.length ? (
          <nav className="header__nav is-close">
            <div>
              <Link to="#" onClick={navigateBack}>
                Close
              </Link>
            </div>
          </nav>
        ) : (
          <>
            <button
              onClick={toggleMenu}
              className={
                isVisible ? "header__hamburger active" : "header__hamburger"
              }
            >
              {isVisible ? "Close" : "Menu"}
            </button>
            <nav className={isVisible ? "header__nav active" : "header__nav"}>
              <div>
                <Link to="/people" activeClassName="active-link">
                  People
                </Link>
              </div>
              <div>
                <Link
                  to="/#services"
                  activeClassName="active-link"
                  onClick={scrollToSection}
                >
                  Expertise
                </Link>
              </div>
              <div>
                <Link to="/resources" activeClassName="active-link">
                  Resources
                </Link>
              </div>
              <div>
                <Link to="/contact" activeClassName="active-link">
                  Contact
                </Link>
              </div>
            </nav>
          </>
        )}
      </header>
    </>
  )
}

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

Header.propTypes = {
  closeAndNavigateTo: PropTypes.string,
}

Header.defaultProps = {
  closeAndNavigateTo: "",
}

export default Header
